.youtubeVideos figcaption  {

    color: white;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    padding-top: .5em;
}

/* .youtubeVideos iframe {
    
} */
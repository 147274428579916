.release {
    border: 10px;
    border-color: goldenrod;
    background-color: yellow;
    border-style: solid;
    fill: yellow;
    max-width: 5000px;
    padding: 3em;
    color: black;
}

.release a {
    color: black;
    font-size: 200%;
    text-decoration: none;
}


.release a:hover {
    color: black;
    font-size: 200%;
    text-decoration: none;
    border-color: goldenrod;
    background-color: goldenrod;
}

.release a:active {
    color: black;
    font-size: 200%;
    text-decoration: none;
}

.release a:visited {
    color: black;
    font-size: 200%;
    text-decoration: none;
}
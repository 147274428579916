.container {
    display: flex; /* or inline-flex */
    justify-content: center;
    margin: auto;
    align-items: center;
    padding-bottom: .75%;
}

.container h2 {
    padding-left: 7.5px;
    margin-top: 0;
    margin-bottom: 0;
}